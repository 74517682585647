
    var ret = {
      defaultLocale: "en-US",
      fallbackLocale: "en-US",
      availableLocales: ["af-ZA","ar-AE","da","de","de-CH","en-US","es","fi-FI","fr-FR","hu","id-ID","it-IT","ja-JP","nb-NO","nl-NL","pl","pt-BR","ro","ru","sk-SK","sv","tr-TR","zh-CN"],
      bundled: {},
      loadables: {},
    };

  // Bundled locales are directly available in the main bundle.
  
    {
      var suffixes = [".ftl"];
      var contents = [];
    
      contents.push(require("/var/coral/locales/en-US/common.ftl").default);
    

      contents.push(require("/var/coral/locales/en-US/framework.ftl").default);
    

      contents.push(require("/var/coral/locales/en-US/ui.ftl").default);
    
      contents = contents.concat(suffixes.map(function(suffix) { return require("/var/coral/locales/en-US/admin" + suffix).default; }));
      ret.bundled["en-US"] = contents.join("\n");
    }
  

  // Loadables are in a separate bundle, that can be easily loaded.
  
    ret.loadables["af-ZA"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-af-ZA", webpackMode: "lazy" */
          "/var/coral/locales/af-ZA/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-af-ZA", webpackMode: "lazy-once" */
          "/var/coral/locales/af-ZA/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ar-AE"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ar-AE", webpackMode: "lazy" */
          "/var/coral/locales/ar-AE/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ar-AE", webpackMode: "lazy" */
          "/var/coral/locales/ar-AE/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ar-AE", webpackMode: "lazy" */
          "/var/coral/locales/ar-AE/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ar-AE", webpackMode: "lazy-once" */
          "/var/coral/locales/ar-AE/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["da"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/var/coral/locales/da/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/var/coral/locales/da/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/var/coral/locales/da/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy-once" */
          "/var/coral/locales/da/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["de"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy" */
          "/var/coral/locales/de/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy" */
          "/var/coral/locales/de/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy" */
          "/var/coral/locales/de/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy-once" */
          "/var/coral/locales/de/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["de-CH"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-CH", webpackMode: "lazy" */
          "/var/coral/locales/de-CH/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-CH", webpackMode: "lazy" */
          "/var/coral/locales/de-CH/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-de-CH", webpackMode: "lazy-once" */
          "/var/coral/locales/de-CH/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["es"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/var/coral/locales/es/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/var/coral/locales/es/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/var/coral/locales/es/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy-once" */
          "/var/coral/locales/es/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["fi-FI"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy" */
          "/var/coral/locales/fi-FI/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy" */
          "/var/coral/locales/fi-FI/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy-once" */
          "/var/coral/locales/fi-FI/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["fr-FR"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/var/coral/locales/fr-FR/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/var/coral/locales/fr-FR/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/var/coral/locales/fr-FR/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy-once" */
          "/var/coral/locales/fr-FR/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["hu"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-hu", webpackMode: "lazy" */
          "/var/coral/locales/hu/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-hu", webpackMode: "lazy" */
          "/var/coral/locales/hu/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-hu", webpackMode: "lazy" */
          "/var/coral/locales/hu/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-hu", webpackMode: "lazy-once" */
          "/var/coral/locales/hu/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["id-ID"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-id-ID", webpackMode: "lazy" */
          "/var/coral/locales/id-ID/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-id-ID", webpackMode: "lazy" */
          "/var/coral/locales/id-ID/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-id-ID", webpackMode: "lazy" */
          "/var/coral/locales/id-ID/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-id-ID", webpackMode: "lazy-once" */
          "/var/coral/locales/id-ID/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["it-IT"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-it-IT", webpackMode: "lazy" */
          "/var/coral/locales/it-IT/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-it-IT", webpackMode: "lazy" */
          "/var/coral/locales/it-IT/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-it-IT", webpackMode: "lazy" */
          "/var/coral/locales/it-IT/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-it-IT", webpackMode: "lazy-once" */
          "/var/coral/locales/it-IT/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ja-JP"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ja-JP", webpackMode: "lazy" */
          "/var/coral/locales/ja-JP/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ja-JP", webpackMode: "lazy" */
          "/var/coral/locales/ja-JP/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ja-JP", webpackMode: "lazy" */
          "/var/coral/locales/ja-JP/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ja-JP", webpackMode: "lazy-once" */
          "/var/coral/locales/ja-JP/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["nb-NO"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nb-NO", webpackMode: "lazy" */
          "/var/coral/locales/nb-NO/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nb-NO", webpackMode: "lazy" */
          "/var/coral/locales/nb-NO/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nb-NO", webpackMode: "lazy" */
          "/var/coral/locales/nb-NO/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-nb-NO", webpackMode: "lazy-once" */
          "/var/coral/locales/nb-NO/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["nl-NL"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy" */
          "/var/coral/locales/nl-NL/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy" */
          "/var/coral/locales/nl-NL/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy" */
          "/var/coral/locales/nl-NL/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy-once" */
          "/var/coral/locales/nl-NL/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["pl"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/var/coral/locales/pl/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/var/coral/locales/pl/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/var/coral/locales/pl/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy-once" */
          "/var/coral/locales/pl/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["pt-BR"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/var/coral/locales/pt-BR/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/var/coral/locales/pt-BR/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/var/coral/locales/pt-BR/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy-once" */
          "/var/coral/locales/pt-BR/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ro"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy" */
          "/var/coral/locales/ro/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy" */
          "/var/coral/locales/ro/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy-once" */
          "/var/coral/locales/ro/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ru"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy" */
          "/var/coral/locales/ru/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy" */
          "/var/coral/locales/ru/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy" */
          "/var/coral/locales/ru/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy-once" */
          "/var/coral/locales/ru/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["sk-SK"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sk-SK", webpackMode: "lazy" */
          "/var/coral/locales/sk-SK/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sk-SK", webpackMode: "lazy" */
          "/var/coral/locales/sk-SK/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sk-SK", webpackMode: "lazy" */
          "/var/coral/locales/sk-SK/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-sk-SK", webpackMode: "lazy-once" */
          "/var/coral/locales/sk-SK/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["sv"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/var/coral/locales/sv/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/var/coral/locales/sv/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/var/coral/locales/sv/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy-once" */
          "/var/coral/locales/sv/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["tr-TR"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-tr-TR", webpackMode: "lazy" */
          "/var/coral/locales/tr-TR/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-tr-TR", webpackMode: "lazy" */
          "/var/coral/locales/tr-TR/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-tr-TR", webpackMode: "lazy" */
          "/var/coral/locales/tr-TR/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-tr-TR", webpackMode: "lazy-once" */
          "/var/coral/locales/tr-TR/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["zh-CN"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-zh-CN", webpackMode: "lazy" */
          "/var/coral/locales/zh-CN/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-zh-CN", webpackMode: "lazy" */
          "/var/coral/locales/zh-CN/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-zh-CN", webpackMode: "lazy" */
          "/var/coral/locales/zh-CN/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-zh-CN", webpackMode: "lazy-once" */
          "/var/coral/locales/zh-CN/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  
    module.exports = ret;
  